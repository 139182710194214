const BASE_URL = "https://app.ljnath.com";
const PROXY_BASE_UR = "https://gatekeeper.ljnath.com";
const BASE_API_URL = BASE_URL + "/gatekeeper/v3";

const REST_CONSTANTS = {
    GET_LICENSES: BASE_API_URL + "/licenses/all",
    UPDATE_LICENSE: BASE_API_URL + "/licenses/license",
    GET_USERS: BASE_API_URL + "/users/all",
    UPDATE_USER: BASE_API_URL + "/users/user",
    CREATE_USER: BASE_API_URL + "/users/user",
    GET_USER_LICENSES: BASE_API_URL + "/licenses/all/filterBy?createdBy={EMAIL}",
    GET_NODES: BASE_API_URL + "/nodes/all",
    UPDATE_NODE: BASE_API_URL + "/nodes/node",
    GET_SUPPORT_REQUESTS: BASE_API_URL + "/support-request/all",
    UPDATE_SUPPORT_REQUEST: BASE_API_URL + "/support-request/request",
    CREATE_SUPPORT_REQUESTS: BASE_API_URL + "/support-request/request",
    GET_SUPPORT_RESPONSE: BASE_API_URL + "/support-response/all",
    GET_USER_NODES: BASE_API_URL + "/nodes/all/filterBy?createdBy={EMAIL}",
    GET_USER_SUPPORT_REQUEST: BASE_API_URL + "/support-request/all/filterBy?createdBy={EMAIL}",
    GET_USER_SUPPORT_RESPONSE: BASE_API_URL + "/support-response/all/filterBy?createdBy={EMAIL}",
    GET_USER_APPS: BASE_API_URL + "/applications/all/filterBy?createdBy={EMAIL}",
    GET_APPS: BASE_API_URL + "/applications/all",
    ADD_APP: BASE_API_URL + "/applications/application",
    GET_APP_TOKENS: BASE_API_URL + "/app-tokens/all",
    GET_USER_APP_TOKENS: BASE_API_URL + "/app-tokens/all/filterBy?createdBy={EMAIL}",
    CREATE_APP_TOKEN: BASE_API_URL + "/app-tokens/app-token",
    UPDATE_APP_TOKEN: BASE_API_URL + "/app-tokens/app-token",
    GET_SUBSCRIPTIONS: BASE_API_URL + "/subscriptions/all",
    GET_USER_SUBSCRIPTIONS: BASE_API_URL + "/subscriptions/all/filterBy?createdBy={EMAIL}",
    UPDATE_SUBSCRIPTIONS: BASE_API_URL + "/subscriptions/subscription",
    GET_USER_INFO: BASE_API_URL + "/users/user/{EMAIL}",
    GET_USER_STATS: BASE_API_URL + "/users/user/stats",
    CHANGE_USER_PASSWORD: BASE_API_URL + "/users/user/changePassword",
    LOGIN: BASE_API_URL + "/users/user/login",
    GET_FILE: BASE_API_URL + "/support-response/response/{ID}/download",
    PROXY_LOGIN: PROXY_BASE_UR + "/proxy/login",
    PROXY_REGISTER: PROXY_BASE_UR + "/proxy/register",
    PROXY_SUPPORT: PROXY_BASE_UR + "/proxy/support"
}

export default REST_CONSTANTS;
