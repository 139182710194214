import styled from "@emotion/styled";

const Copyright = () => {
    return (
        <Wrapper className="text-center l1-txt4">
            © 2023-2025 <a href="https://ljnath.com/" target="_blank">Lakhya's Innovation Inc.</a> | Made with <span className="love">♥</span>  from <a href="https://www.google.com/maps/place/Assam/" target="_blank">Assam, India</a>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    text-align: center;
    margin: 24px 0 0 0;
    padding-bottom: 8px;
    font-size: 12px;
    color: #fff;
    .love {
        color: red;
    }
`;

export default Copyright;
