import { toast } from "react-hot-toast";
import { useRecoilValue } from "recoil";
import { USER_ROLES } from "../../../../common/constants";
import Gateway from "../../../../common/Gateway";
import { user, userRole } from "../../../../state/user";
import columns from "./../../../../config/tables/subscriptions.json";
import LoadedComponent from "../../../LoadedComponent";
import Util from "../../../../utils/Util";

const Subscriptions = () => {
    const role = useRecoilValue(userRole);
    const userDetails = useRecoilValue(user);
    const loader = async (email, role) => {
        if (role === USER_ROLES.ADMIN) {
            const response = await Gateway.getSubscriptions();
            return response;
        } else {
            const response = await Gateway.getUserSubscriptions(email);
            return response;
        }
    }
    const getData = () => {
        try {
            const email = userDetails.getEmail();
            return loader(email, role);
        } catch (err) {
            toast.error("Failed to fetch subscriptions");
        }
    }
    return <LoadedComponent
        loaded={!!role}
        title="Subscriptions"
        type="subscriptions"
        dataKey="subscriptions"
        columns={columns}
        handleEdit={(data) => {
            const editableData = Util.getEditableData(data, columns);
            return Gateway.updateSubscription(editableData);
        }}
        getData={getData}
    />;
}

export default Subscriptions;
